export default function ({ redirect }) {
  function isChromeAboveVersion110() {
    const userAgent = navigator.userAgent;
    const chromeMatch = userAgent.match(/Chrome\/(\d+)/);

    if (chromeMatch && chromeMatch.length > 1) {
      const version = parseInt(chromeMatch[1], 10);
      return version > 110;
    }

    return false;
  }

  // check the screen width to see if it's a mobile device
  function isMobile() {
    return window.innerWidth <= 768;
  }

  function hideWarning() {
    const warning = document.getElementById("browser-warning");
    if (warning) {
      document.body.removeChild(warning);
    }
    const styleElement = document.getElementById("browser-warning-style");
    if (styleElement) {
      document.head.removeChild(styleElement);
    }
  }

  function showWarning() {
    if (document.getElementById("browser-warning")) {
      return;
    }
    const warning = document.createElement("div");
    warning.id = "browser-warning";
    warning.style.position = "fixed";
    warning.style.top = "0";
    warning.style.left = "0";
    warning.style.width = "100%";
    warning.style.height = "100%";
    warning.style.backgroundColor = "white";
    warning.style.zIndex = "1000";
    warning.style.display = "flex";
    warning.style.justifyContent = "center";
    warning.style.alignItems = "center";
    warning.style.padding = "20px";
    warning.style.boxSizing = "border-box";
    warning.style.fontSize = "16px"; // Default font size
    warning.style.textAlign = "center"; // Ensure text is centered
    warning.style.overflow = "auto"; // Adds scroll if content overflows
    warning.style.wordWrap = "break-word"; // Ensure words break properly

    warning.innerHTML = `<div style="display:flex;flex-direction:column;align-items:center; gap: 10px;"><span>Medview Spark is only accessible <span class="non_desktops"> on Desktop </span> <span class="on_desktops">on Chrome or a Chrome-based browser</span>.</span><br /><span class="on_desktops">Please download <a style="padding: 0 5px;" href="https://www.google.com/chrome/">Chrome</a> to access spark.</span></div>`;
    document.body.appendChild(warning);

    // Responsive font size and layout
    const styleElement = document.createElement("style");
    styleElement.id = "browser-warning-style";
    styleElement.textContent = `
      // .on_desktops { display: none; }
      @media (min-width: 768px) {
        div { font-size: 18px; }
      }
      @media (min-width: 900px) {
        div { font-size: 20px; padding: 40px; }
        .on_desktops { display: block; }
      }
      @media (min-width: 900px) {
        // .non_desktops { display: none; }
      }
    `;
    document.head.appendChild(styleElement);
  }

  function checkBrowser() {
    if (!isChromeAboveVersion110() || isMobile()) {
      showWarning();
    } else {      
      hideWarning();
    }
  }

  document.body.style.display = 'block';

  checkBrowser();

  window.addEventListener('resize', checkBrowser);
}